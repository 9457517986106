@import './src/assets/css/variables.scss';

#receipt-stay-view {
  #non-printable {
    .container {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .is-center {
      text-align: center;
    }

    .button {
      background: transparent;
      border: 0px;
      color: $pink;
      font-weight: bold;
      text-align: center !important;

      i {
        margin-right: 10px;
      }

      &:hover {
        color: $darkPink;
      }

      &:focus {
        outline: 0 !important;
      }
    }

    .card-custom {
      margin: 0 auto;
      background: white;
      padding: 20px 20px;
      border-radius: 10px;
      max-width: 450px;
      box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    }
  }

  #printable {
    display: none;
  }

  #printable,
  #non-printable {
    text-transform: uppercase;

    .logo {
      margin: 0 auto;
    }

    img {
      max-width: 150px;
    }

    h2 {
      color: black;
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    b {
      color: black;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
    }

    p {
      color: black;
      margin-bottom: 5px;
      line-height: 18px;
    }

    hr {
      box-shadow: none;
      background: none;
      border-top: 1px solid black;
    }

    .hr-top {
      margin-top: 20px;
      margin-bottom: 15px;
    }

    .hr-bottom {
      margin-top: 17px;
      margin-bottom: 20px;
    }

    .hr-top-bottom {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .footer-print {
      p {
        text-align: center;
        font-size: 15px;
      }
    }
  }
}

@media print {
  header {
    display: none !important;
  }

  .main {
    min-height: auto !important;
  }

  #non-printable {
    display: none;
  }

  #printable {
    display: block !important;
  }

  // #printable, #printable * {
  //   visibility: visible;
  // }

  // #printable {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  // }

  #printable p:last-child {
    page-break-after: auto !important;
  }
}