// Fontes
$familyPrimary: "Lato", sans-serif;

// Cores
$bodyColor: #f5f2f2;
$darkPink: #640032;
$hoverPink: #da1a54;
$pink: #a20148;
$textColor: rgba(0, 0, 0, 0.6);
$buttonColor: #a20148;
$hoverButton: #da1a54;
$darkPrimaryColor: #006d6c;
$subtitleColor: #007978;
$hrColor: rgba(0, 0, 0, 0.15);
$footerColor: #f5f5f5;
$linkColor: $darkPrimaryColor;
$dividerColor: #e1e1e1;