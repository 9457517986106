@import url("https://fonts.googleapis.com/css?family=Lato:300,400,400i,700");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "./src/assets/css/variables.scss";
@import "~bulma/bulma";

// Esse arquivo é para declaração de atributos que serão utilizados em todas as páginas...
// O CSS de cada página está em seu respectivo component ou view!

* {
  font-family: $familyPrimary;
  font-size: 16px;
}

.main {
  min-height: calc(100vh);
}

body {
  margin: 0;
  padding: 0;
  background-color: $bodyColor;
}
.efeito-button {
  color: $pink !important;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background: none;
  border: none;
  box-shadow: none !important;

  &:hover {
    color: $hoverPink !important;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

a {
  color: $pink !important;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    color: $hoverPink !important;
  }
}

table {
  margin-top: 33px;
  width: 100%;

  tr {
    td {
      color: $textColor;
      font-size: 16px;
      padding-top: 24px;
      padding-bottom: 24px;
    }

    th {
      color: $darkPink;
      font-size: 14px;
      padding-bottom: 8px;
    }

    th:first-child,
    td:first-child {
      padding-left: 20px;
    }

    th:last-child,
    td:last-child {
      padding-right: 60px;
    }
  }
}

.error {
  background-color: white;
  border-radius: 4px;
  padding: 30px 30px;
  margin-top: 30px;

  h1 {
    color: rgba(0, 0, 0, 0.6);
    font-size: 24px;
    margin-bottom: 10px;
  }

  p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
  }
}

.srv-validation-message {
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.38;
  color: $darkPink;
}

.answer {
  color: $darkPink;

  .material-icons {
    vertical-align: -6px;
    margin-right: 5px;
  }
}

.is-loading-custom {
  margin: 0 auto;
  -webkit-animation: spinAround 500ms infinite linear;
  animation: spinAround 500ms infinite linear;
  border: 2px solid $darkPink;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 3em;
  position: relative;
  width: 3em;
}

.pagination {
  margin-top: 40px;
  margin-bottom: 40px;

  .pagination-link {
    color: $darkPink;
    padding-top: 8px;

    .material-icons {
      margin-top: -3px !important;
    }

    &:hover {
      border-color: $darkPink !important;
    }

    &:active {
      box-shadow: none !important;
    }

    &:focus {
      box-shadow: none !important;
      border-color: #dbdbdb;
    }
  }

  .is-current {
    background-color: $darkPink;
    border-color: $darkPink;
    color: #fff !important;
  }

  .not-border {
    border: none;
  }
}

select {
  &:disabled {
    cursor: no-drop !important;
  }
}

@include mobile {
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    td {
      padding-right: 15px;
    }
  }
}
